var releaseHandler = {
    onProcess: 0,
    setOutput: function (e) {
        --this.onProcess;
        var span = e.data == '1' ? '.updated' : '.updated-error';
        $(e.sender).siblings(span).show().siblings('span').hide();
    },
    process: function (e) {
        var sender = '.release-' + e.id + '-' + e.website;
        $(sender).siblings('.onload').show().siblings('span').hide();
        ++this.onProcess;
        $.post(window.updateScheduleDateUrl, e)
            .success(function (data) {
                releaseHandler.setOutput({sender: sender, data: data});
            })
            .error(function (error) {
                releaseHandler.setOutput({sender: sender, data: error});
            });
        ;
    },
    init: function () {
        $('.ajax-alert').hide();

        $('.datepicker').datepicker({
            minDate: new Date(),
            dateFormat: 'mm/dd/yy',
            goToCurrent: true,
            onSelect: function (dateText, inst) {
                releaseHandler.process({
                    id: $(this).data('idvideo'),
                    releaseDate: dateText,
                    website: $(this).data('website')
                });
            }
        });

        jQuery(window).bind('beforeunload', function (event) {
            if (releaseHandler.onProcess > 0) {
                event.stopPropagation();
                event.returnValue = 'A video release date is still being updated.';
                return event.returnValue;
            }
        });
    }
};


var thumbsManager = {
    replaceThumb: function (err, data, thumbToChange) {
        var videoItem = $('.video' + data.idVideo),
            mainThumb = videoItem.find('.video-thumbnail div img');

        mainThumb.siblings('.play-icon').show().siblings('span.thumb-updating').hide();
        videoItem.find('.thumbs-gallery ul').removeClass('onloading');

        $('.video' + data.idVideo + ' li[data-thumb="' + thumbToChange + '"]').addClass('selected').siblings('li').removeClass('selected');

        if (err) {
            alert(err);
        } else {
            mainThumb.hide().attr('src', data.imgPath).fadeIn('slow');
        }
    },
    updateThumb: function (sender) {
        var _this = this,
            sender = $(sender),
            videoClass = sender.closest('.video-item').attr('class'),
            idVideoItem = videoClass.substr(5, videoClass.indexOf(' ') - 5),
            thumbId = sender.data('thumb'),
            imgPath = sender.children('img:first').attr('src'),
            thumbList = sender.parent().data('thumblist'),
            thumbIndex = sender.data('thumbindex'),
            data = {
                idVideo: idVideoItem,
                thumbID: thumbId,
                imgPath: imgPath,
                thumbList: thumbList,
                thumbIndex: thumbIndex
            };
        var thumbToChange = sender.data('thumb');

        $('.video' + idVideoItem + ' li[data-thumb="' + thumbToChange + '"]').parent().addClass('onloading').closest('.thumbs-selection').siblings('.video-thumbnail').find('div .play-icon').hide().siblings('span.thumb-updating').show();


        $.post(window.updateMasterThumbUrl, data)
            .success(function () {
                _this.replaceThumb(null, data, thumbToChange);
            })
            .error(function (error) {
                _this.replaceThumb('An error occured whilst updating the image. Please try again.', data);
            });
    },
    init: function () {
        var _this = this;

        $('.thumbs-selection a').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active').siblings('.thumbs-gallery').slideToggle();
        });

        $('.thumbs-gallery li').click(function (e) {
            _this.updateThumb(this);
        });
    }
};

var pageUtils = {
    filter: function (sort, page, status, website) {
        var idSite;

        $('#siteFilter').each(function () {
            idSite = this.value;
        });
        if (!page || page < 1) {
            page = 1;
        }
        if (!sort) {
            sort = window.location.search.match(/sort=([a-z]+)/);
            if (sort && sort.length == 2) {
                sort = sort[1];
            } else {
                sort = 'submitted';
            }
        }
        if (!status) {
            status = window.location.search.match(/status=([a-z]+)/);
            if (status && status.length == 2) {
                status = status[1];
            } else {
                status = '';
            }
        }
        if (!website) {
            website = window.location.search.match(/website=([a-z]+)/);
            if (website && website.length == 2) {
                website = website[1];
            } else {
                website = '';
            }
        }

        if (idSite !== '' || status !== 'all' || sort !== 'submitted' || page.toString() !== '1' || website !== '') {
            window.location.search = '?idSite=' + idSite + '&status=' + status + '&sort=' + sort + '&p=' + page.toString() + '&website=' + website;
        } else {
            window.location = 'myvideos';
        }
    },
    paginationHooks: function () {
        $('.pagination ul li a').each(function () {
            var page = this.href;
            page = page.substr(page.indexOf('?'));
            page = page.match(/\?p=([0-9]+)/);
            if (page.length == 2) {
                page = page[1];
                this.href = 'javascript:void(0);';
                $(this).click(function () {
                    pageUtils.filter(null, page, null);
                    return false;
                });
            }
        });
    },
    init: function () {
        this.paginationHooks();
    }
};


$(document).ready(function () {
    releaseHandler.init();
    pageUtils.paginationHooks();
    thumbsManager.init();
});

$('.thumbs-selection').on('click', function () {
    $(this).find('img[data-src]').each(function () {
        if ($(this).data('src') != $(this).attr('src')) {
            $(this).attr('src', $(this).data('src'));
            $(this).removeAttr('data-src');
        }
    });
});

$('#editAnnotationsToggle').on('click', function () {
    $('#templateSubMenu').toggle();
    $('.templateSelector').toggle();
    $('#video-listings .checker').toggle();
    $(this).toggleClass('active');
    $(this).val('Close Annotations');
});

$("#selectAllAnnotations").click(function () {
    $('.enableAnnotations').prop('checked', this.checked);
    $.uniform.update('.enableAnnotations');
});

$('#applyTemplate').click(function () {
    const data = [];
    $('.checked .enableAnnotations').each(function () {
        data.push($(this).val());
    });
    if (data.length) {
        $('#selectedTemplate').val($('#templateSelector').val());
        $('#selectedVideos').val(data);
        const form = $("#addAnnotations");
        $.ajax({
            type: "POST",
            url: form.attr('action'),
            data: form.serialize(),
            success: function(data) {
                CppMessages.info('Template applied');
                if (data.message) {

                    CppMessages.info(data.message);
                }
            }
        });
    }
});

$(window).load(function () {
    $('.video-thumbnail img[data-src]').each(function () {
        $(this).attr('src', $(this).data('src'));
        $(this).removeAttr('data-src');
    });
});

