$('#reconfirm').click(function (e) {
    e.preventDefault();
    $.ajax({
        url: this.href
    }).done(function() {
        CppMessages.info('A new confirmation email has been succesfully sent!');
    });
});

$( window ).load(function() {
  $('.skypebutton').attr('src', $('.skypebutton').data('src'));
  $('.skypebutton').removeAttr('data-src');
});

$('#change-website').change(function() {
    window.location.search = '?website=' + $(this).val();
});
