var CppMessages = {
	warn : function(msg){
                $("html, body").animate({ scrollTop: "0px" });
		var el = $('.warning');
		if(msg != null)
			el.find('p').html(msg);
		el.removeClass('hidden').hide().slideDown('slow');

	},
	info : function(msg)
	{
		var el = $('.success');
		if(msg != null)
			el.find('p').html(msg);
		el.removeClass('hidden').hide().slideDown('slow');
		$("html, body").animate({ scrollTop: "0px" });
	},
	infoAjax : function(msg)
	{
		var el = $('.success');
		if(msg != null)
			el.html('<p class="prefix_2">' + msg + '<p>');

		el.removeClass('hidden').hide().slideDown('slow');
		$("html, body").animate({ scrollTop: "0px" });
	},
	infoAjaxHide : function()
	{
		var el = $('.success');
		el.html('');
	},
	addError : function(msg){
		if(msg == null)
		{
			log('inside PornTube.addError', this, 'Error message was empty');
			return;
		}
        var el = $('.error');
        el.removeClass('hidden').hide().slideDown('slow');
        el.find('ul').append('<li>'+ msg +'</li>')
	},
	clearErrors : function(){
		var el = $('.error');
        el.find('ul').html('')
	},

	clearErrorsAjax : function(){
		var el = $('.error');
        el.find('ul').html('')
        el.slideUp('slow').addClass('hidden');
	},

	clearSuccess : function(){
		var el = $('.success');
		el.empty();
	},
	clearWarnings : function(){
		var el = $('.warning');
		el.empty();
	},
	hide : function(){
        $('.server-message').slideUp('slow').addClass('hidden');
	},
	hideAll : function(){
        $('.server-message').slideUp('slow').addClass('hidden');
        $('.error').slideUp('slow').addClass('hidden');
	}
};
