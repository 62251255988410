$(function() {
    $('#addSite_description').simplyCountable({
        counter: '#char_counter',
        countType: 'characters',
        maxCount: 300,
        countDirection: 'down'
    });
});

function showMobileUrlFields() {
    if ($("#addSite_useDesktopLinkcode").is(':checked') ) {
        $("#mobile_affialiate_url").addClass('hidden');
    } else {
        $("#mobile_affialiate_url").removeClass('hidden');
    }
}

$('#addSite_useDesktopLinkcode').click(function () {
    showMobileUrlFields();
});

showMobileUrlFields();

$(function() {
    $('#main .bannerModal').fancybox({titleShow: false});
});

$(function() {
    $(".switch_custodian").click(function(){
        if( $(this).attr('rel') == "switch1") {
            $(".switch_add_site2").removeClass("hidden");
            $(".switch_add_site1").addClass("hidden");
            $("#add_custodian").val("1");

        }	else {
            $(".switch_add_site2").addClass("hidden");
            $(".switch_add_site1").removeClass("hidden");
            $("#add_custodian").val("0");
        }
    });
});