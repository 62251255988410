$('#stepOne_termsAndConditions').change(function(e) {
    if ($(this).is(':checked')) {
        $("#submit").removeClass('disabled');
        $("#submit").removeAttr('disabled');
    } else {
        $("#submit").addClass('disabled');
        $("#submit").attr('disabled', 'disabled');
    }
});
$('#stepOne_termsAndConditions').change();
