$('.edit-btn').click(function (e) {
    var id = $(this).data("id");
    $('.form-' + id).show();
    e.preventDefault();
});

$('.btn-cancel').click(function () {
    var id = $(this).data("id");
    $('.form-' + id).hide();
});
