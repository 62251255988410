$('#bulkChoices a').click(function(e){
    e.preventDefault();

    var sender = $(this),
            target = sender.attr('href');

    if(sender.hasClass('selected')) {
            return;
    }

    $('#bulkChoices a').toggleClass('selected');

    $(target).fadeIn().siblings('.bulk-tutorial').hide();
    $('#bulkChoices').toggleClass('secondary');
});

$('#bulkLog .bulksprite.error').tipsy({html: true, fade: true, gravity: 'e'});
